<template lang="pug">
  b-row.mb-5
    b-col

      b-card.manager-identities-list.card-white.card-no-gutters.shadow(no-body)
        b-card-header.bg-transparent.border-0
          wc-tables-header(
            :title="$t('activerecord.models.identity.other')"
            @click-data-clean-fetch='dataCleanFetch'
            @click-data-fetch='dataFetch')

        b-card-body
          b-row
            b-col
              wc-tables-filter(
                :filter.sync='filter'
                :p-page.sync='pagination.pPage'
                :p-page-options='pagination.pPageOptions'
                @submit='dataFetch'
                @submit-clear='filterClear')

          b-row
            b-col
              transition(name='fade')
                wc-tables-wrapper-default(
                  :ref='wns'
                  :items='items'
                  :fields='fields'
                  :sort-by.sync='sorting.sortBy'
                  :sort-desc.sync='sorting.sortDesc'
                  :is-loading='isLoading'
                  :action-delete='actionDestroy'
                  @row-clicked='rowClicked'
                  @row-delete='rowDelete'
                  @sort-changed='sortingChanged'
                  attr-active)
                  template(v-slot:cell(attributes.identifiable_type_snake)='data')
                    | {{ $t(`activerecord.models.${data.item.attributes.identifiable_type_snake}.one`) }}

        b-card-footer.border-0.bg-white
          wc-tables-footer(
            :total-items='pagination.totalItems'
            :p-page='pagination.pPage'
            :c-page.sync='pagination.cPage')

        loading(
          :active.sync='isLoading'
          :can-cancel-esc='true'
          :on-cancel='onCancel')
</template>

<script>
import Table from '@common/table'
import apiIdentities from '@services/api/manager/identities'

export default {
  name: 'manager-identities-list',
  mixins: [Table],
  props: {
    wns: {
      type: String,
      default: 'ide',
    },
  },
  methods: {
    routeParams(item) {
      return {
        edit: [{ name: 'manager-identities-edit', params: { identity_id: item.attributes.sid } }],
      }
    },
  },
  data() {
    return {
      apiBase: apiIdentities,
      actionRowClicked: 'edit',
      fields: [
        {
          key: 'id',
          label: this.$t('activerecord.attributes.shared.id'),
          sortable: true,
          attr: 'identities.id',
          class: 'dots-100 h-100',
          noClick: true,
        },
        {
          key: 'attributes.username',
          label: this.$t('activerecord.attributes.identity.username'),
          sortable: true,
          attr: 'identities.username',
        },
        {
          key: 'attributes.identifiable_type_snake',
          label: this.$t('activerecord.attributes.identity.identifiable_type'),
          sortable: true,
          attr: 'identities.identifiable_type',
        },
        {
          key: 'attributes.discarded_at',
          label: this.$t('activerecord.attributes.shared.discarded_at'),
          sortable: true,
          attr: 'identities.discarded_at',
        },
        {
          key: 'attributes.updated_at',
          label: this.$t('activerecord.attributes.shared.updated_at'),
          sortable: true,
          attr: 'identities.updated_at',
        },
        {
          key: 'attributes.created_at',
          label: this.$t('activerecord.attributes.shared.created_at'),
          sortable: true,
          attr: 'identities.created_at',
        },
      ],
    }
  },
}
</script>
