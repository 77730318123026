const alertDestroy = {
  methods: {
    fireDestroy(options = {}) {
      return this.$swal({
        title: this.$t('alerts.destroy.title'),
        text: this.$t('alerts.destroy.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('alerts.destroy.confirm_button'),
        cancelButtonText: this.$t('alerts.destroy.cancel_button'),
        reverseButtons: true,
        ...options,
      })
    },
    fireDestroyCancel(options = {}) {
      return this.$swal({
        title: this.$t('alerts.destroy.cancelled.title'),
        text: this.$t('alerts.destroy.cancelled.text'),
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
        ...options,
      })
    },
  },
}

const alertAction = {
  methods: {
    fireAction(options = {}) {
      return this.$swal({
        title: this.$t('alerts.action.title'),
        text: this.$t('alerts.action.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('alerts.action.confirm_button'),
        cancelButtonText: this.$t('alerts.action.cancel_button'),
        reverseButtons: true,
        ...options,
      })
    },
    fireActionCancel(options = {}) {
      return this.$swal({
        title: this.$t('alerts.action.cancelled.title'),
        text: this.$t('alerts.action.cancelled.text'),
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
        ...options,
      })
    },
  },
}

const alertLogin = {
  methods: {
    fireLogin(options = {}) {
      return this.$swal({
        title: this.$t('alerts.login.title'),
        text: this.$t('alerts.login.text'),
        icon: 'warning',
        showCloseButton: true,
        confirmButtonText: this.$t('alerts.login.confirm_button'),
        ...options,
      })
    },
  },
}

const alertImage = {
  methods: {
    fireImage(options = {}) {
      return this.$swal({
        title: this.$t('alerts.image.title'),
        text: this.$t('alerts.image.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('alerts.image.confirm_button'),
        cancelButtonText: this.$t('alerts.image.cancel_button'),
        reverseButtons: true,
        ...options,
      })
    },
  },
}

const alertFile = {
  methods: {
    fireFile(options = {}) {
      return this.$swal({
        title: this.$t('alerts.file.title'),
        text: this.$t('alerts.file.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('alerts.file.confirm_button'),
        cancelButtonText: this.$t('alerts.file.cancel_button'),
        reverseButtons: true,
        ...options,
      })
    },
  },
}

export { alertDestroy, alertAction, alertLogin, alertImage, alertFile }
